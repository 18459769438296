.grade-A {
    background-color: #00b24b !important;
}

.grade-B {
    background-color: #67ff17 !important;
}

.grade-C {
    background-color: #ffff01 !important;
}

.grade-D {
    background-color: #ff6503 !important;
}

.grade-F {
    background-color: #f60500 !important;
}

.grade-E {
    background-color: #f60500 !important;
}

.grade- {
    background-color: #9E9E9E !important;
}

/* A level grade colors */
.grade-AA {
    background-color: #00b24b !important;
}

.grade-AB {
    background-color: #67ff17 !important;
}

.grade-AC {
    background-color: #67ff17 !important;
}

.grade-AD {
    background-color: #ffff01 !important;
}

.grade-AE {
    background-color: #ffff01 !important;
}

.grade-AF {
    background-color: #f60500 !important;
}

.grade-AS {
    background-color: #ff6503 !important;
}
