/* ConfirmationDialog.css */
.dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.dialog-container {
    background: white;
    padding: 20px;
    border-radius: 0px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
    text-align: center;
}

.dialog-title {
    margin: 0;
    font-size: 1.5em;
}

.dialog-message {
    margin: 20px 0;
    font-size: 1em;
}

.dialog-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}
