.vertical-text {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    padding: 0;
    margin: 0;
}

.nowrap {
    white-space: nowrap;
}

table thead {
    font-family: Tahoma, Geneva, Verdana, sans-serif;
}

table tr td {
    font-family: Arial, Helvetica, sans-serif;
}

tr {
    font-size: 0.9rem;
}

#printContent {
    background-color: #add8e6;
}

@media print {
    #printContent {
        background-color: #add8e6;
    }
}

.necta-format {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: separate;
    text-align: center;
    background-color: #faf3dd;
    border-top: 1px solid #71848a;
    border-left: 1px solid #71848a;
    border-right: 1px solid #292926;
    border-bottom: 1px solid #292926;
}

.necta-format-title {
    color: #800080;
    font-size: 1.1rem;
}

.necta-format caption {
    caption-side: top;
    color: #800080;
    font-size: 1.1rem;
    padding: 0px;
    margin-bottom: 0px;
}

.necta-format th, 
.necta-format td {
    font-size: 0.75rem;
    color: #2a2996;
    padding: 0px 4px;
    min-width: 64px;
    border-right: 1px solid #71848a;
    border-bottom: 1px solid #71848a;
    border-left: 1px solid #292926;
    border-top: 1px solid #292926;
}

.necta-format th {
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
}

.necta-format td {
    font-size: 0.7rem;
}

.necta-format-collapse {
    border-collapse: collapse;
    border: 2px solid #292926;
}

.necta-format-collapse th {
    border: 2px solid #292926;
} 

.necta-format-collapse td {
    border: 2px solid #292926;
}
