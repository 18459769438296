.link{
    cursor: pointer;
}

.user_dropdown{
    position: absolute;
    right: 0px;
    margin-left: auto;
    width: fit-content;
    background-color: var(--background-color);
    padding-right: 1.5rem;
    z-index: 1;
}

.user_dropdown .link{
    color: var(--first-color-light);
}

.user_dropdown .link:hover{
    color: var(--white-color);
}